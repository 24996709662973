import ready from 'domready'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Entry } from './Entry'

const entry = (
  <StrictMode>
    <Entry />
  </StrictMode>
)

ready(() => {
  document.body = document.createElement('body')
  const container = document.createElement('main')
  document.body.appendChild(container)
  createRoot(container).render(entry)
})
