import styled from '@emotion/styled'
import { FC } from 'react'

const Frame = styled('iframe')`
  width: 375px;
  height: 667px;
  border: 1px solid #000;
  overflow: auto;
`

export const Entry: FC = () => {
  return <Frame src='/stk.html' />
}
